<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Edit a Scenario'"
      /> -->
      <v-card-title class="mt-3 pt-6 pl-8">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Edit a Scenario</span>
      </v-card-title>
      <common-page-loader v-if="loading" />
      <common-trs-card class="mx-8 pb-0 mb-2">
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
          class="ml-auto"
        >
          <v-card-text class="ml-n2 px-0">
            <scenario-form
              v-model="scenariosData"
              :editable="true"
            />
          </v-card-text>
          <v-divider />
          <v-row class="d-flex justify-space-between">
            <v-col>
              <common-trs-btn
                type="secondary"
                medium
                class="pull-right mr-2"
                text
                :to="{name: 'Scenarios'}"
              >
                Cancel
              </common-trs-btn>
            </v-col>
            <v-col class="text-right">
              <common-trs-btn
                type="primary"
                medium
                class="white--text"
                text
                :disabled="!valid"
                :loading="loading"
                @click="update"
              >
                Update
              </common-trs-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </common-trs-card>
    </template>
  </common-trs-page-container>
</template>

<script>
  import ScenarioForm from '@/components/common/captable/components/forms/ScenarioForm'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'EditScenarios',

    components: {
      ScenarioForm,
    },

    data: () => ({
      scenariosData: {},
      loading: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      scenarioId () {
        return this.$route.query.id
      },
    },

    created () {
      this.loadExistingScenario()
    },

    methods: {
      clearNullData (element) {
        Object.keys(element).forEach((key) => {
          if (element[key] === null || element[key] === undefined) {
            delete element[key]
          }
        })
      },

      createScenarioData () {
        this.scenariosData.scenario_rounds.map((element) => {
          return this.clearNullData(element)
        })

        this.scenariosData.scenario_rounds.map((element) => {
          return this.clearNullData(element.liquidation_preference)
        })
      },
      async update () {
        this.loading = true
        this.createScenarioData()
        try {
          await captableService.updateScenarioById(this.captableId, this.scenarioId, this.scenariosData)
          this.loading = false
          this.$store.dispatch('app/message', { text: 'Scenario updated successfully.' })
          this.$router.push({ name: 'Scenarios' })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to update scenario. Please try again later.',
          })
        }
      },
      async loadExistingScenario () {
        try {
          const resp = await captableService.getScenariosById(this.captableId, this.scenarioId)
          this.scenariosData = resp.data
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      close () {
        this.$router.push({ name: 'Scenarios' })
      },
    },
  }
</script>
