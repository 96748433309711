<template>
  <v-form ref="form">
    <v-row class="ma-0 mt-4">
      <v-col
        cols="6"
        class="py-0"
      >
        <validation-provider
          :name="'Scenario name'"
          :rules="{ required: true, max: 20 }"
        >
          <common-input-text-field
            v-model="internalValue.name"
            label="Scenario name"
            color="primary"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
        lg="6"
        md="6"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Exit value'"
          :rules="{ required: true, max: 20 }"
        >
          <common-input-currency-input
            v-model="waterfallData.exit_amount"
            label="Exit value"
            color="primary"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
        class="py-0"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Exit date'"
          :rules="{ required: true, max: 20 }"
        >
          <input-template-date
            v-model="exitsOn"
            :mask="'##/##/####'"
            :input-templ="{label: 'Exit date', mandatory: true}"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            clearable
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row v-if="!editable">
      <common-trs-a-link
        type="tertiary"
        large
        class="ml-6 d-flex primary--text text-body-1 pt-1 font-weight-light cursor-pointer font-weight-medium"
        text
        @click="addFinancingRound"
      >
        <v-icon class="mr-2 primary--text">
          {{ showFinancingForm ? 'mdi-minus' :'mdi-plus' }}
        </v-icon>
        {{ showFinancingForm? 'Remove Financing Round' :'Add Financing Round' }}
      </common-trs-a-link>
    </v-row>
    <v-row class="ma-0">
      <v-col>
        <financing-round-common-form
          v-if="showFinancingForm"
          v-model="financingRoundForm"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import FinancingRoundCommonForm from './ScenarioFinancingForm'

  export default {
    name: 'ScenarioForm',

    components: {
      FinancingRoundCommonForm,
      InputTemplateDate,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
      editable: {
        type: Boolean,
        required: true,
      },
    },
    data: () => ({
      showFinancingForm: false,
      waterfallData: {},
      financingRoundForm: {},
      exitsOn: '',
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (val) {
            this.waterfallData = val.waterfall || {}
            this.exitsOn = this.waterfallData.exits_on ? moment(this.waterfallData.exits_on).format('MM/DD/YYYY') : ''
          }
        },
      },
      exitsOn: {
        handler (val) {
          if (val) {
            this.waterfallData.exits_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateValue () {
        this.internalValue.waterfall = this.waterfallData
        if (!this.editable) {
          this.internalValue.scenario_rounds = this.showFinancingForm ? [this.financingRoundForm] : []
        }
      },
      addFinancingRound () {
        this.showFinancingForm = !this.showFinancingForm
        this.updateValue()
      },
    },
  }
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  margin-top: 0 !important;
}
.grace-period-row {
  line-height: 55px;
  background: var(--v-accent-base);
  border-radius: 4px;
}
</style>
